import { Input, message, Modal, Select, Spin, Button, Row, Col, Divider, Table, Slider, Progress } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios'
import Datatopbox from "./Components/Datatopbox";
import UploadModel from '@/components/UploadModel'


const { confirm } = Modal

let indexObj: any = {}
let carid: any = ""

let leftSearch = {
  brand: '',
  attr: 'all'
}

let standard_label = ""

const model_type_arr: any = [
  {
    label: '车身',
    value: 'car_body',
    key: 'car_body'
  },
  {
    label: '底盘',
    value: 'car_chassis',
    key: 'car_chassis'
  },
  {
    label: '发动机',
    value: 'car_engine',
    key: 'car_engine'
  },
  {
    label: '电池',
    value: 'car_battery',
    key: 'car_battery'
  },
  {
    label: '变速箱',
    value: 'car_transmission',
    key: 'car_transmission'
  }
]

let active_car_id = ""
let model_type = ''

const Tcar: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth, brandsList } = store
  const { pathname } = useLocation()
  const [keyword, setKeyword] = useState<string>("")
  const [brandCode, setBrandCode] = useState<string>('haval')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [vinOpen, setVinOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [vinData, setVinData] = useState<any>([{vin: "", pos: "", oe: ""}])

  const [attrList, setAttrList] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const [vinList, setVinList] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)

  const [updateType, setUpdateType] = useState<number>(1)

  const [farkOpen, setFarkOpen] = useState<any>(false)
  const [vinLoading, setVinLoading] = useState<boolean>(false)

  const [brand, setBrand] = useState<any>("奥迪")
  const [selectModelOptions, setSelectModelOptions] = useState<any>([])

  const [selectModel, setSelectModel] = useState<any>("")
  

  useEffect(() => {
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      getList()
    }
  },[brand])

  useEffect(() => {
    getSelectModelOptions()
  }, [model_type])


  


  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      contextMenu: [
        { 
          key: 'price', 
          title: '修改oe', 
          label: 'Ctrl+R', 
          keyCode: 82,
          onClick: moreOeUpdate
        },
      ],
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 10,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',(e: any) => console.log(e))

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        active_car_id = rowData[indexObj['id']] ? rowData[indexObj['id']].text : ''
        let other = ['car_body', 'car_chassis', 'car_engine', 'car_battery', 'car_transmission']
        if(ci === indexObj['car_body']) {
          model_type = 'car_body'
          setOpen(true)
        }else if(ci === indexObj['car_chassis']) {
          model_type = 'car_chassis'
          setOpen(true)
        }else if(ci === indexObj['car_engine']) {
          model_type = 'car_engine'
          setOpen(true)
        }else if(ci === indexObj['car_battery']) {
          model_type = 'car_battery'
          setOpen(true)
        }else if(ci === indexObj['car_transmission']) {
          model_type = 'car_transmission'
          setOpen(true)
        }
      }
      
    })
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        if(other.includes(item.key)) {
          cells.push({
            text,
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text,
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const moreOeUpdate = (data: any) => {
    setOpen(true)
    let _data = data.filter((it: any) => {
      return it.ci === indexObj['oe'] && it.ri
    })
    console.log('menu-price data:', data)
    if(_data.length === 1) {
      let ri = _data[0].ri
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      console.log(rowData)
      let _carid = rowData[indexObj['carsid']] ? rowData[indexObj['carsid']].text : ''
      carid = _carid
      dataApi.getOeList({carsid: carid, standard_label}).then((res: any) => {
        if(res.code === 1) {
          res.data.forEach((it: any) => {
            it.pos = it.remark
          })
          setVinData(res.data)
          setOpen(true)
          setUpdateType(1)
        }
      })
    }else {
      let _carid = ""
      _data.forEach((it: any) => {
        let ri = it.ri
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        _carid += rowData[indexObj['carsid']] ? ',' + rowData[indexObj['carsid']].text : ''
      })
      carid = _carid
      setOpen(true)
      setUpdateType(2)
      setVinData([{vin: "", pos: "", oe: ""}])
    }
  }
  const getList = () => {
    let params = {
      brand
    }
    setLoading(true)
    axios.get("/api/v1/car-info/complete", {params}).then((res: any) => {
      setLoading(false)
      if(res) {
        let header = [
          {
            label: '品牌',
            key: 'brand'
          },
          {
            label: '车系',
            key: 'series'
          },
          {
            label: '车型',
            key: 'model'
          },
          {
            label: '操作时间',
            key: 'operate_time'
          },
          ...model_type_arr
        ]
        let list = res
        initData(list, header)
      }
    })
  }


  const submit = () => {
    let params = {
      three_d_model_id: ''
    }
    setSubmitLoading(true)
    axios.post(`/api/v1/car-infos/${active_car_id}/model-mapping`, params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 200) {
        setOpen(false)
        message.success("操作成功")
      }
    })
  }

  const getSelectModelOptions = () => {
    let params = {
      model_type: model_type
    }
    axios.get("/api/v1/three-d-models", {params}).then((res: any) => {
      if(res.code === 200) {
        let _list = res.data.map((item: any) => {
          return {
            label: item.model_name,
            value: item.id,
          }
        })
        setSelectModelOptions(_list)
      }
    })
  }

  const handleModelData = (option: any) => {
    // let { label } = option
    // let _s_name = label.split("-")[0]
    // let _pos= label.split("-")[1]
    // jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['oe_id'], {
    //   text: option.value,
    //   editable: true,
    // }, 'all')
    // jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['s_name'], {
    //   text: _s_name,
    //   editable: true,
    // }, 'all')
    // jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['pos'], {
    //   text: _pos,
    //   editable: true,
    // }, 'all')
    // jexcelRef.current.reRender()

    // setuUpdateSid(option.value)
    // setOpen(false)
    
    message.success("操作成功！")
    
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Select
        className="mr12"
        style={{ width: 200 }}
        showSearch
        filterOption={(input: any, option: any) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={brandsList}
        placeholder="品牌"
        value={brand || undefined}
        onChange={(value) => setBrand(value)}
      />
      <Spin spinning={loading}>
        <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
      </Spin>
      

      <Modal
        title="关联"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={submit}
      >
        <div className='mtb20'>
          <Select
            placeholder="请选择需要关联的模型"
            style={{ width: '100%' }}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={selectModelOptions}
            value={selectModel}
            onChange={(value: any, option: any) => handleModelData(option)}
          />
        </div>
      </Modal>

    </div>
  )
}

export default Tcar








