import { Input, message, Modal, Select, Spin, Button, Row, Col, Divider, Table, Slider, Progress } from 'antd'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import context from '@/context/MainContext'
import dataApi from '@/api/dataApi'
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios'
import Datatopbox from "./Components/Datatopbox";
import UploadModel from '@/components/UploadModel'
import html2canvas from 'html2canvas'


const { confirm } = Modal

let indexObj: any = {}
let carid: any = ""

let leftSearch = {
  brand: '',
  attr: 'all'
}
let rightSearch = {
  vin: '',
}
let searchTag = 0
let standard_label = ""

const model_type_arr: any = [
  {
    label: '车身',
    value: 'car_body'
  },
  {
    label: '底盘',
    value: 'car_chassis'
  },
  {
    label: '发动机',
    value: 'car_engine'
  },
  {
    label: '电池',
    value: 'car_battery'
  },
  {
    label: '变速箱',
    value: 'car_transmission'
  }
]
let model_type_obj: any = {
  car_body: '车身',
  car_chassis: '底盘',
  car_engine: '发动机',
  car_battery: '电池',
  car_transmission: '变速箱'
}

let active_model_id = ""

const Tmodel: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { writeAuth } = store
  const { pathname } = useLocation()
  const [keyword, setKeyword] = useState<string>("")
  const [brandCode, setBrandCode] = useState<string>('haval')
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [updateOpen, setUpdateOpen] = useState<boolean>(false)
  const [vinOpen, setVinOpen] = useState<boolean>(false)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({})

  const [vinData, setVinData] = useState<any>([{vin: "", pos: "", oe: ""}])

  const [brandsList, setBrandsList] = useState<any>([])
  const [standardList, setStandardList] = useState<any>([])
  const [attrList, setAttrList] = useState<any>([])

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  const [vinList, setVinList] = useState<any>([])

  const [change, setChange] = useState<boolean>(false)

  const [updateType, setUpdateType] = useState<number>(1)

  const [farkOpen, setFarkOpen] = useState<any>(false)
  const [vinLoading, setVinLoading] = useState<boolean>(false)

  

  useEffect(() => {
    initExcel()

    setTimeout(() => {
      getList()
    })
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])


  useEffect(() => {
    dataApi.getOtherBrandList().then((res: any) => {
      if(res.code === 1) {
        let _options = res.data.map((it: any) => {
          return {
            label: it,
            value: it
          }
        })
        setBrandsList(_options)
      }
    })
  },[])



  


  const initExcel = async () => {

    let excel: any = document.getElementById(pathname)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      contextMenu: [
        { 
          key: 'price', 
          title: '修改oe', 
          label: 'Ctrl+R', 
          keyCode: 82,
          onClick: moreOeUpdate
        },
      ],
      mode: 'edit', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 9,
        width: 130
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)
    

    // jexcelRef.current.on('cell-edited',(e: any) => console.log(e))

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        active_model_id = rowData[indexObj['id']] ? rowData[indexObj['id']].text : ''
        if(ci === indexObj['operate']) {
          setOpen(true)
        }else if(ci === indexObj['car_mapping_count']) {
          setFarkOpen(true)
        }
      }
      
    })
    // jexcelRef.current.on("scrollbar-move", (e: any) => {
    //   console.log(e)
    // })
    // jexcelRef.current.on('menu-price', menuPriceClick)
    // jexcelRef.current.on('cells-selected', cellsSelected);
    console.log(jexcelRef.current)
    
    jexcelRef.current.validate()
  }
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj = {}
    
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj[item.key] = index
        let text = items[item.key] || ''
        if(other.includes(item.key)) {
          cells.push({
            text,
            editable: false,
            style: 0
          })
        }if(item.key === 'operate') {
          cells.push({
            text: '编辑',
            editable: true
          })
        }else {
          cells.push({
            text,
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const moreOeUpdate = (data: any) => {
    setOpen(true)
    let _data = data.filter((it: any) => {
      return it.ci === indexObj['oe'] && it.ri
    })
    console.log('menu-price data:', data)
    if(_data.length === 1) {
      let ri = _data[0].ri
      let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
      console.log(rowData)
      let _carid = rowData[indexObj['carsid']] ? rowData[indexObj['carsid']].text : ''
      carid = _carid
      dataApi.getOeList({carsid: carid, standard_label}).then((res: any) => {
        if(res.code === 1) {
          res.data.forEach((it: any) => {
            it.pos = it.remark
          })
          setVinData(res.data)
          setOpen(true)
          setUpdateType(1)
        }
      })
    }else {
      let _carid = ""
      _data.forEach((it: any) => {
        let ri = it.ri
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        _carid += rowData[indexObj['carsid']] ? ',' + rowData[indexObj['carsid']].text : ''
      })
      carid = _carid
      setOpen(true)
      setUpdateType(2)
      setVinData([{vin: "", pos: "", oe: ""}])
    }
  }
  const getList = () => {
    let params = {
    }
    setLoading(true)
    axios.get("/api/v1/three-d-models", {params}).then((res: any) => {
      setLoading(false)
      if(res.code === 200) {
        let header = [
          {label: '类型', key: 'model_type_name'},
          {label: '名称', key: 'model_name'},
          {label: '安卓文件', key: 'url_for_android'},
          {label: 'IOS文件', key: 'url_for_ios'},
          {label: '图片', key: 'preview_image_url'},
          {label: '关联车型', key: 'car_mapping_count'},
          {label: '完成度', key: 'part_mapping_count'},
          {label: '操作时间', key: 'operate_time'},
          {label: '操作', key: 'operate'},
          {label: 'id', key: 'id'},
          {label: '类型', key: 'model_type'},
        ]
        res.data.forEach((it: any) => {
          it.model_type_name = model_type_obj[it.model_type]
        })
        let list = res.data
        initData(list, header)
      }
    })
  }


  const submit = () => {
    let params = {
      brand: leftSearch.brand,
      standard_label,
      carsid: carid,
      oes: vinData
    }
    setSubmitLoading(true)
    dataApi.updateOe(params, { headers: {
      'Content-Type': 'application/json'
    } }).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 1) {
        setOpen(false)
        message.success("操作成功")
        getList()
      }
    })
  }

  return (
    <div className='p10 bgc-fff' >
      {/* <Datatopbox/> */}
      <Spin spinning={loading}>
        <div style={{ position: 'relative' }}>
          <Button
            style={{ position: 'absolute', right: 10, top: 5 }}
            type="primary"
            onClick={() => {active_model_id = "" ;setOpen(true)}}
          >添加模型</Button>
          <div className='mt10' style={{ height: 'calc(100vh - 110px)' }} ref={jexcelDivRef} id={pathname}></div>
        </div>
      </Spin>
      

      <Modal
        title="vin详细"
        open={vinOpen}
        onCancel={() => setVinOpen(false)}
        footer={null}
      >
        <div className='mtb20'>
          
        </div>
      </Modal>

      {
        open ? (
          <Emodel
            key={active_model_id}
            onCancel={() => setOpen(false)}
            model_id={active_model_id}
          />
        ): null
      }

      {
        farkOpen ? (
          <Fark
            onCancel={() => setFarkOpen(false)}
            model_id={active_model_id}
          />
        ): null
      }
    </div>
  )
}

export default Tmodel

let indexObj1: any = {}
let part_sn = ''
let midIndex = -1
// 新增修改模型
const Emodel: React.FC<any> = (props) => {
  const { pathname } = useLocation()
  const { onCancel, model_id } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [activeItem, setActiveItem] = useState<any>({model_id})
  const [attributes, setAttributes] = useState<any>({})

  const [open, setOpen] = useState<boolean>(false)
  const [stdOptions, setStdOptions] = useState<any>([])
  const [updateSid, setuUpdateSid] = useState<any>("")

  const [ sliderVal, setSliderVal ] = useState<number>(0)
  const [ progress, setProgress ] = useState<number>(0)
  const [submitLoading, setSubmitLoading] = useState<boolean>(false)

  const [change, setChange] = useState<boolean>(false)

  const [stdTip, setStdTip] = useState<string>("")
  const [stdTipOpen, setStdTipOpen] = useState<boolean>(false)

  const model = useRef<any>(null)

  const canUpdate_3d = useRef<any>(null) 

  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  let header = [
    {
      label: '模型ID',
      key: 'part_sn'
    },
    {
      label: '标准名称',
      key: 's_name'
    },
    {
      label: '方位',
      key: 'pos'
    },
    {
      lable: '标准id',
      key: 'oe_id'
    }
  ]

  useEffect(() => {
    if(activeItem.url_for_android) {
      if((activeItem.model_id && canUpdate_3d.current) || !activeItem.model_id) {
        update3d()
      }
    }
  },[activeItem.url_for_android])


  useEffect(() => {
    getStdOptions()
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    if(activeItem.model_id) {
      getModelDetail()
    }
  },[activeItem.model_id])

  const getModelDetail = async () => {
    let res: any = await axios.get(`/api/v1/three-d-models/${activeItem.model_id}`)
    if(res.code === 200) {
      if(res.data.attribute) {
        setAttributes(res.data.attribute)
      }
      delete res.data.attribute
      setActiveItem({
        model_id: res.data.id,
        model_name: res.data.model_name,
        preview_image_url: res.data.preview_image_url,
        url_for_ios: res.data.url_for_ios,
        url_for_android: res.data.url_for_android,
        model_type: res.data.model_type,

      })
    }
    let otherRes: any = await axios.get(`/api/v1/three-d-models/${model_id}/part-mappings`)
    canUpdate_3d.current = true
    update3d(otherRes.data || [], res.data.url_for_android)
  }

  const update3d = (data?: any, inputFile?: any) => {
    console.log(1111)
    let file = inputFile || activeItem.url_for_android
    model.current = (window as any).matchbox({
      file,
      id: 'container',
      scale: 1.5,
      activeColor: '#FF00FF',
      loaded: function(val: any){
        setProgress(val)
      },
      click: (obj: any, status: any) => {
        console.log(obj, status)
        // if(click){
        //   click(obj, status)
        // }
        // let url = model.current.generateImg()
        // console.log(url)
        jexcelRef.current.setScrollbar(0, '0810')
      },
      sceneBack(ids: any) {
        if(data && (data.length || inputFile)) {
          initData(data, header)
          return false
        }
        function getDuplicates(arr: any) {
          return [...new Set(arr) as any].filter(item => arr.indexOf(item) !== arr.lastIndexOf(item));
        }
        const duplicates = getDuplicates(ids)
        if(duplicates.length) {
          setStdTip(duplicates.join(","))
          setStdTipOpen(true)
        }
        let _ids = [...new Set(ids) as any]
        
        let list = _ids.map((id: any) => {
          return {
            part_sn: id,
            s_name: '',
            pos: ''
          }
        })
        initData(list, header)
        
      }
    })
    const setModel = (data: any) => {
      model.current.setActive(data, true, true, '#FF00FF')
    }
  }
  const explosion = (val: any) => {
    model.current.applyScalar(val)
    setSliderVal(val)
  }

  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj1 = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj1[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname + '1')
    console.log(excel?.clientWidth)
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'read', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 3,
        width: 90
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)

    jexcelRef.current.on('cell-selected', (e: any, ri: any, ci: any) => {
      console.log(11)
      if(ri > 0) {
        let rowData = jexcelRef.current.datas[0].rows.getOrNew(ri).cells
        part_sn = rowData[indexObj1['part_sn']] ? rowData[indexObj1['part_sn']].text : ''
        let old_sid =  rowData[indexObj1['oe_id']] ? rowData[indexObj1['oe_id']].text : ''
        midIndex = ri
        if(ci === indexObj1['s_name']) {
          if(old_sid) {
            setuUpdateSid(old_sid)
          }else {
            setuUpdateSid("")
          }
          setOpen(true)
        }
      }
      
    })

    jexcelRef.current.validate()
  }

  const submit = () => {
    console.log(jexcelRef.current.getData()[0].rows)
    
    // return false
    let params = {
      ...activeItem,
      attributes,
      preview_image_url: activeItem.preview_image_url || "https://cdns.007vin.com/img/qiye_rank_banner_210324.png",
      url_for_ios: 'https://cdns.007vin.com/img/qiye_rank_banner_210324.png'
    }
    let api: any
    setSubmitLoading(true)
    if(activeItem.model_id) {
      api = axios.put(`/api/v1/three-d-models/${activeItem.model_id}`, params)
    }else {
      api = axios.post("/api/v1/three-d-models", params)
    }
    api.then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 200) {
        setOpen(false)
        // if(activeItem.model_id) {
        //   let rows = jexcelRef.current.getData()[0].rows || []
        //   let oeList: any = []
        //   rows.forEach((it: any) => {
        //     let cells = it.cells
        //     let part_sn = cells[indexObj1['part_sn']].text || ""
        //     let oe_id = cells[indexObj1['oe_id']].text || ""
        //     oeList.push({
        //       part_sn: part_sn || undefined,
        //       oe_id: oe_id || undefined
        //     })
        //   })
        //   let params = {
        //     data: oeList
        //   }
        //   setSubmitLoading(true)
        //   axios.post(`/api/v1/three-d-models/${activeItem.model_id}/part-mappings`, params).then((oeRes: any) => {
        //     setSubmitLoading(false)
        //     if(oeRes.code === 200) {
        //       message.success("操作成功！")
        //       setOpen(false)
        //     }
        //   })
        // }
      }
    })
  }

  const getStdOptions = () => {
    axios.get("/api/v1/oe-standard-names").then((res: any) => {
      if(res.code === 200) {
        let _list = res.data.map((it: any) => {
          return {
            label: it.name + `${it.extend ? '-' + it.extend : ''}`,
            value: it.id,
            ...it
          }
        })
        setStdOptions(_list)
      }
    })
  }
  const handleStdData = (option: any) => {
    let { label } = option
    let _s_name = label.split("-")[0]
    let _pos= label.split("-")[1]
    jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['oe_id'], {
      text: option.value,
      editable: true,
    }, 'all')
    jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['s_name'], {
      text: _s_name,
      editable: true,
    }, 'all')
    jexcelRef.current.datas[0].rows.setCell(midIndex, indexObj1['pos'], {
      text: _pos,
      editable: true,
    }, 'all')
    jexcelRef.current.reRender()

    setuUpdateSid(option.value)
    setOpen(false)
    
    message.success("操作成功！")
    
  }
  return (
    <>
      <Modal
        title={`${activeItem.model_id ? '编辑' : '新增'}模型`}
        open={true}
        onCancel={() => onCancel()}
        width={1300}
        centered
        okButtonProps={{
          loading: submitLoading
        }}
        onOk={submit}
      >
        <Spin spinning={loading}>
          <div className='flex mt30'>
            <div className='flex-1'>
              <div className='flex-s-c'>
                <label><span className='red'>*</span>类型：</label>
                <Select
                  options={model_type_arr}
                  value={activeItem.model_type}
                  onChange={(value) => setActiveItem({...activeItem, model_type: value})}
                  style={{ width: 120 }}
                  disabled={activeItem.id}
                />
                <label className='ml15'><span className='red'>*</span>名称：</label>
                <Input
                  value={activeItem.model_name}
                  style={{ width: 160 }}
                  onChange={(e: any) => setActiveItem({...activeItem, model_name: e.target.value})}
                />
                <label className='ml10'><span className='red'>*</span>文件：</label>
                <div className=' flex-s-c'>
                  <UploadModel
                    item={activeItem}
                    lkey="iourl_for_ios"
                    uploadLabel="上传IOS文件(加密zip)"
                    updateWrap={() => setActiveItem({...activeItem})}
                  />
                  <div className='mlr5'></div>
                  <UploadModel
                    item={activeItem}
                    lkey="url_for_android"
                    uploadLabel="上传安卓文件(Gltf)"
                    updateWrap={() => setActiveItem({...activeItem})}
                  />
                </div>
              </div>
              <div className='flex-s-c mt10'>
                <label><span className='red'>*</span>参数：</label>
                {
                  activeItem.model_type === 'car_chassis' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择燃油方式"
                        options={['汽油', '纯电', '混动', '柴油'].map((it: string) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.fuel || undefined}
                        onChange={(value: any) => { setAttributes({...attributes, fuel: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择驱动方式"
                        options={[{label: '前驱', value: 'front_drive'},{label: '后驱', value: 'rear_drive'},{label: '四驱', value: 'four_wheel_drive'}]}
                        value={attributes.drive_mode || undefined}
                        onChange={(value: any) => { setAttributes({...attributes, drive_mode: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择前悬架"
                        options={['麦弗逊', '双叉臂', '多连杆', '空气减震'].map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.front || undefined}
                        onChange={(value: any) => { setAttributes({...attributes, front: value}) }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择后悬架"
                        options={['多连杆', '扭力梁', '整体桥', '双叉臂', '空气减震'].map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.rear || undefined}
                        onChange={(value: any) => { setAttributes({...attributes, rear: value}) }}
                      />
                    </>
                  ):activeItem.model_type === 'car_engine' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择燃油方式"
                        options={['汽油', '柴油', '纯电', '混动'].map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.fuel_type}
                        onChange={(value: any) => {
                          setAttributes({...attributes, fuel_type: value})
                        }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择气缸数"
                        options={['3','4','5','6','8','10','12'].map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.cylinders_num}
                        onChange={(value: any) => {
                          setAttributes({...attributes, cylinders_num: value})
                        }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择布局"
                        options={'L型、V型、W型、H型、R型'.split('、').map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.engine_layout}
                        onChange={(value: any) => {
                          setAttributes({...attributes, engine_layout: value})
                        }}
                      />
                    </>
                  ):activeItem.model_type === 'car_battery' ? (
                    <>
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择电池品牌"
                        options={'宁德时代、比亚迪、LG、松下、三星SDI、SK On、欣旺达、蜂巢能源、孚能科技、亿纬锂能、中创新航、瑞浦兰钧、国轩高科'.split('、').map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.brand}
                        onChange={(value: any) => {
                          setAttributes({...attributes, brand: value})
                        }}
                      />
                      <Select
                        style={{ width: 130, marginRight: 10 }}
                        placeholder="选择电池类型"
                        options={['三元锂电池','磷酸铁锂电池','钴酸锂电池','镍氢电池','石墨烯电池','燃料电池'].map((it: any) => {
                          return {
                            label: it,
                            value: it
                          }
                        })}
                        value={attributes.battery_type}
                        onChange={(value: any) => {
                          setAttributes({...attributes, battery_type: value})
                        }}
                      />
                    </>
                  ):activeItem.model_type === 'car_battery' ? (
                    <Select
                      style={{ width: 130, marginRight: 10 }}
                      placeholder="选择变速箱类型"
                      options={['手动变速箱（MT）','自动变速箱（AT）','机械无级自动变速箱（CVT）','电控机械自动变速箱（AMT）','双离合变速箱（DCT/DSG）','电子控制变速箱（ECT）'].map((it: any) => {
                        return {
                          label: it,
                          value: it
                        }
                      })}
                      value={attributes.transmission_type}
                      onChange={(value: any) => {
                        setAttributes({...attributes, transmission_type: value})
                      }}
                    />
                  ): null
                }
              </div>

              <div className='flex-c-c mt10' style={{ width: '100%', height: 450 }}>
                {
                  activeItem.url_for_android ? (
                    <div style={{ position: 'relative', border: '1px solid #f8f8f8', height: 450 }}>
                      <div id="container" style={{ width: 800, height: 400 }}></div>
                      <div className='flex-c-c'>
                        <Slider style={{ width: 300 }} min={0} max={100} value={sliderVal} onChange={(val)=>explosion(val)} />
                      </div>
                    </div>
                  ): (
                    <UploadModel
                      item={activeItem}
                      lkey="url_for_android"
                      updateWrap={() => setActiveItem({...activeItem})}
                    >
                      <div className='flex-c-c pointer' style={{ flexDirection: 'column', width: 180, height: 180, background: '#EDF4FE', color: '#096BED' }}>
                        <i className='iconfont icon-guanlian' style={{ fontSize: 30 }} />
                        <p>上传安卓文件</p>
                      </div>
                    </UploadModel>
                  )
                }
              </div>

            </div>
            <div  style={{ width: 600, height: 600 }} ref={jexcelDivRef} id={pathname + '1'}></div>
          </div>
        </Spin>
      </Modal>

      <Modal
        open={open}
        title={`选择标准名称(${part_sn})`}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div className='flex-s-c mt20'>
          <label style={{ width: 120 }}>标准名称-方位</label>
          <Select
            style={{ flex: 1 }}
            showSearch
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={stdOptions}
            value={updateSid}
            onChange={(value: any, option: any) => handleStdData(option)}
          />
        </div>
      </Modal>
      
      <Modal
        title='提示'
        open={stdTipOpen}
        onCancel={() => setStdTipOpen(false)}
        okText="是"
        cancelText="否"
        onOk={() => {
          setActiveItem({...activeItem, url_for_android: ''})
          setStdTipOpen(false)
          let a = {
            name: '',
            merges: [],
            rows: [],
            autofilter: {
              filters: [],
              ref: 'B1:O1'
            },
            cols: {
              
            },
            freeze: "A2",
            styles: [
              {
                bgcolor: '#d8d8d8',
                font: {
                  bold: true
                }
              },
              {
                color: '#ff0000'
              },
              {
                color: '#ec8d00'
              }
            ]
          }
          jexcelRef.current.loadData([a])
        }}
        onClose={() => {
          setStdTipOpen(false)
        }}
      >
        <div className='mtb20'>
          存在重复的模型下id：
          <p>{stdTip}</p>
          是否需要重新选择模型？
        </div>
      </Modal>

    </>
    
  )
}




let indexObj2: any = {}
let selectIndexs: any = []
// 关联车型
const Fark: React.FC<any> = (props) => {
  const { store } = useContext(context)
  const { brandsList } = store
  const { pathname } = useLocation()
  const { onCancel, model_id } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [brand, setBrand] = useState<any>('奥迪')

  const [submitLoading, setSubmitLoading] = useState<boolean>(false)
  
  let jexcelDivRef: any = useRef<HTMLDivElement>(null)
  let jexcelRef: any = useRef<any>({})

  useEffect(() => {
    initExcel()
    return () => {
      jexcelDivRef.current = null
      jexcelRef.current = {}
    }
  },[])

  useEffect(() => {
    if(jexcelDivRef.current && JSON.stringify(jexcelRef.current ) !== "{}") {
      getList()
    }
  },[brand])

  const getList = () => {
    let params = {
      brand
    }
    setLoading(true)
    axios.get("/api/v1/car-info/complete", {params}).then((res: any) => {
      setLoading(false)
      let header = [
        {
          label: '品牌',
          key: 'brand'
        },
        {
          label: '车系',
          key: 'series'
        },
        {
          label: '车型',
          key: 'model'
        },
        {
          lable: 'id',
          key: 'id'
        }
      ]
      let list = res
      initData(list, header)
    })
  }
  
  const initData = async (list: any, headers: any) => {
    let _rows: any = { len: list.length + 100 }
    let cells: any = []
    headers.forEach((item: any) => {
      cells.push({
        text: item.label,
        editable: false,
        style: 0
      })
    })
    _rows[0] = {
      cells
    }
    let other: any = []
    indexObj2 = {}
    list.forEach((items: any, j: number) => {
      let cells: any = []
      headers.forEach((item: any, index: number) => {
        indexObj2[item.key] = index
        if(other.includes(item.key)) {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: false,
            style: 0
          })
        }else {
          cells.push({
            text: items[item.key] ? items[item.key] : '',
            editable: true
          })
        }
        
      })
      _rows[j + 1] = {
        cells
      }
    })

    let excelDataObj = {
      name: '',
      merges: [],
      rows: _rows,
      autofilter: {
        filters: [],
        ref: 'B1:O1'
      },
      cols: {
        
      },
      freeze: "A2",
      styles: [
        {
          bgcolor: '#d8d8d8',
          font: {
            bold: true
          }
        },
        {
          color: '#ff0000'
        },
        {
          color: '#ec8d00'
        }
      ]
    }
    jexcelRef.current.loadData([excelDataObj])
  }

  const initExcel = async () => {

    let excel: any = document.getElementById(pathname + '2')
    let width = excel?.clientWidth
    let height = excel?.clientHeight
    var saveIcon = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTc3MTc3MDkyOTg4IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjI2NzgiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+PC9zdHlsZT48L2RlZnM+PHBhdGggZD0iTTIxMy4zMzMzMzMgMTI4aDU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMgODUuMzMzMzMzdjU5Ny4zMzMzMzRhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMgODUuMzMzMzMzSDIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMS04NS4zMzMzMzMtODUuMzMzMzMzVjIxMy4zMzMzMzNhODUuMzMzMzMzIDg1LjMzMzMzMyAwIDAgMSA4NS4zMzMzMzMtODUuMzMzMzMzeiBtMzY2LjkzMzMzNCAxMjhoMzQuMTMzMzMzYTI1LjYgMjUuNiAwIDAgMSAyNS42IDI1LjZ2MTE5LjQ2NjY2N2EyNS42IDI1LjYgMCAwIDEtMjUuNiAyNS42aC0zNC4xMzMzMzNhMjUuNiAyNS42IDAgMCAxLTI1LjYtMjUuNlYyODEuNmEyNS42IDI1LjYgMCAwIDEgMjUuNi0yNS42ek0yMTMuMzMzMzMzIDIxMy4zMzMzMzN2NTk3LjMzMzMzNGg1OTcuMzMzMzM0VjIxMy4zMzMzMzNIMjEzLjMzMzMzM3ogbTEyOCAwdjI1NmgzNDEuMzMzMzM0VjIxMy4zMzMzMzNoODUuMzMzMzMzdjI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjcgNDIuNjY2NjY3SDI5OC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMS00Mi42NjY2NjctNDIuNjY2NjY3VjIxMy4zMzMzMzNoODUuMzMzMzMzek0yNTYgMjEzLjMzMzMzM2g4NS4zMzMzMzMtODUuMzMzMzMzeiBtNDI2LjY2NjY2NyAwaDg1LjMzMzMzMy04NS4zMzMzMzN6IG0wIDU5Ny4zMzMzMzR2LTEyOEgzNDEuMzMzMzMzdjEyOEgyNTZ2LTE3MC42NjY2NjdhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjctNDIuNjY2NjY3aDQyNi42NjY2NjZhNDIuNjY2NjY3IDQyLjY2NjY2NyAwIDAgMSA0Mi42NjY2NjcgNDIuNjY2NjY3djE3MC42NjY2NjdoLTg1LjMzMzMzM3ogbTg1LjMzMzMzMyAwaC04NS4zMzMzMzMgODUuMzMzMzMzek0zNDEuMzMzMzMzIDgxMC42NjY2NjdIMjU2aDg1LjMzMzMzM3oiIHAtaWQ9IjI2NzkiIGZpbGw9IiMyYzJjMmMiPjwvcGF0aD48L3N2Zz4='
    let options: any = {
      showGrid: true,
      showToolbar: true,
      showBottomBar: false,
      showContextmenu: true,
      mode: 'read', //edit
      row: {
        len: 50000,
      },
      col: {
        len: 4,
        width: 260
      },
      view: {
        height: ()=>{return height},
        width: ()=>{return width},
      },
      
    }
    
    jexcelRef.current = new (window as any).x_spreadsheet(jexcelDivRef.current, options)

    jexcelRef.current.on("cell-row-selected", (cell: any, rows: any) => {
      console.log(cell, rows)
      selectIndexs = rows
    })
    jexcelRef.current.validate()
  }

  const submit = () => {
    let _car_info_ids: any = []
    selectIndexs.forEach((index: number) => {
      let item = jexcelRef.current.datas[0].rows.getOrNew(index).cells
      console.log(item)
      let id = item[indexObj2['id']] && item[indexObj2['id']].text ? item[indexObj2['id']].text : ''
      if(id) {
        _car_info_ids.push(id)
      }
    })
    let params = {
      car_info_ids: _car_info_ids
    }
    setSubmitLoading(true)
    axios.put(`/api/v1/three-d-models/${model_id}/car-mappings`, params).then((res: any) => {
      setSubmitLoading(false)
      if(res.code === 200) {
        message.success("操作成功！")
        onCancel()
      }
    })
  }
  return (
    <Modal
      title={`关联车型-奥迪A4`}
      open={true}
      onCancel={() => onCancel()}
      width={1000}
      centered
      okButtonProps={{
        loading: submitLoading
      }}
      onOk={submit}
    >
      <Spin spinning={loading}>
        <div>
          <div className='flex-s-c'>
            <Select
              className="mr12"
              style={{ width: 200 }}
              showSearch
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={brandsList}
              placeholder="品牌"
              value={brand || undefined}
              onChange={(value) => setBrand(value)}
            />
          </div>
          <div className='mt10' style={{ width: '100%', height: 500 }} ref={jexcelDivRef} id={pathname + '2'}></div>
        </div>
      </Spin>
    </Modal>
  )
}

