import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import MainContext from '@/context/MainContext'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import { PartSearch, SearchPartInput } from '@/page/Dataedit/Vin'
import vinApi from '@/api/vinApi'
import { getUrlParams, cdnsPath } from '@/utils/utils'
import css from './vin.module.scss'

import { Input, Button, Row, Col, message, Upload } from 'antd'
import UploadModel from '@/components/UploadModel'


const Vin: React.FC = () => {
  const urlParams = JSON.stringify(getUrlParams())
  const { store, setStore } = useContext(MainContext)
  const { crumb, vinData, editKey } = store
  const navigate = useNavigate()
  const tempRef = useRef<any>({
    upkeep: true
  })
  const { vin, num, subindex, mcid, brandcode, samecrumb, vinmcid, name, vincode, id, title } = getUrlParams()
  const [ data, setData ] = useState<any>({})
  const [ group, setGroup ] = useState<any>([])
  const [ structure, setStructure ] = useState<any>([])
  const [ subGroup, setSubGroup ] = useState<any>([])
  const [ key, setKey ] = useState<string>('')
  const [ groupKey, setGroupKey ] = useState<string>('')

  const [ groupActive, setGroupActive ] = useState<number>(-1)
  const [ structureActive, setStructureActive ] = useState<number>(-1)
  const [ filter, setFilter ] = useState<boolean>(localStorage.getItem('filter') ? true : false)
  const [ structureShow, setStructureShow ] = useState<boolean>(localStorage.getItem('structureShow') ? true : false)
  const [ partSearchkey, setPartSearchkey ] = useState<number>(0)
  const [ partKey, setPartKey ] = useState<string>('')
  const [ groupTotal, setGroupTotal ] = useState<any>({structure: 0, sub: 0})
  const [ subGroupShow, setSubGroupShow ] = useState<any>({menu: 'main', content: false})
  
  const [ configVisible, setConfigVisible ] = useState<boolean>(false)
  const [ config, setConfig ] = useState<any>([])

  const [ selectConfig, setSelectConfig ] = useState<any>({data: {}})
  const [ selectConfigVisible, setSelectConfigVisible ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<number>(0)
  const [ progressVisible, setProgressVisible ] = useState<boolean>(false)

  const [ infoType, setInfoType ] = useState<string>('config')
  const [ upkeepLoading, setUpkeepLoading ] = useState<boolean>(false)

  const [change, setChange] = useState<boolean>(false)

  const [tab, setTab] = useState<'group' | 'special'>("group")

  // 专项件
  const [special_category, set_special_category] = useState<any>([])
  const [specialActive, setSpecialActive] = useState<number>(-1)
  
  // 编辑数据
  const [submitLoading, setSubmitLoading] = useState<any>({
    save: false,
    submit: false
  })
  const [configDetail, setConfigDetail] = useState<any>([])
  const [groupDetail, setGroupDetail] = useState<any>([])
  const [mainEditOpen, setMainEditOpen] = useState<boolean>(false)
  const [activeMainItem, setActiveMainItem] = useState<any>({})

  const [subGroupDetail, setSubGroupDetail] = useState<any>([])
  const [subEditOpen, setSubEditOpen] = useState<boolean>(false)
  const [activeSubItem, setActiveSubItem] = useState<any>({})

  
  let activeIndex = 0

  useEffect(() => {
    if(tab === 'special' && (brandcode || vincode)) {
      vinApi.getSpecialParts({brandCode: brandcode || vincode}).then((res: any) => {
        if(res.code === 1) {
          set_special_category(res.data.special_category_map)
        }
      })
    }
  },[brandcode, tab, vincode])

  useEffect(()=>{
    if(id){
      const param = {
        vin,
        id,
        name,
        pop: true
      }
      setStore({value: param, type: 'param'})
      window.sessionStorage.setItem('param', JSON.stringify(param))
      window.sessionStorage.setItem('searchKey', name || '')
      if(title && brandcode){
        let tempCrumb = []
        title.split('>').forEach((item: string)=>{
          tempCrumb.push({
            name: item,
            type: "config"
          })
        })
        tempCrumb.unshift({
          img: `https://cdns.007vin.com/img/${brandcode}.png`,
          type: "logo"
        })
        setStore({value: tempCrumb, type: 'crumb'})
        window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      }
    }
    // console.info(vinData)
    if((vinData.vin && vinData.vin === vin) || (vinData.mcid && mcid && vinData.mcid === mcid.replace(/\=/g, '%3D'))){
      setData(vinData.data || {})
      setGroup(vinData.group || [])
      setStructure(vinData.structure || [])

      if(num){
        vinData.group.forEach((gitem: any, index: number)=>{
          if(gitem.num == num){
            setGroupActive(index)
          }
        })
      }
      if(subindex){
        
        const subindexArr = subindex.split(',')
        setSubGroup(vinData.subGroup || [])
        setSubGroupShow({
          menu: subindexArr.length === 1 ? 'structure' : subindexArr.length - 2,
          content: true
        })
      }
      
      setGroupTotal(vinData.groupTotal || {structure: 0, sub: 0})
      if(!vinmcid){
        const url = window.location.search + `&vinmcid=${vinData.mcid || vinData.data.mcid}&vincode=${vinData.brandcode || vinData.data.brandCode}`
        navigate(url)
      }
      return
    }
    if(vin){
      getVin(true, urlParams, vin, brandcode)
    }
    
    // catalog
    if(mcid && brandcode){
      getGroup({
        brandCode: brandcode,
        mcid: mcid.replace(/\=/g, '%3D')
      })
    }
  }, [])

  useEffect(()=>{
    if(configVisible){
      searchConfig()
    }
  }, [configVisible])

  useEffect(()=>{
    if(num && group.length){
      group.forEach((item: any, index: number)=>{
        if((num === '0' && !index) || item.num == num || item.tempNum == num){
          // if(!samecrumb){
          //   let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
          //   item.name = item.label
          //   item.type = 'group'
          //   tempCrumb.push(item)
          //   setStore({value: tempCrumb, type: 'crumb'})
          // }
          getStructure(index, true)
        }
      })
    }
  }, [group])

  useEffect(()=>{
    if(subindex && structure.length){
      getSubGroup(subindex.split(',')[0], true)
    }
  }, [structure])

  useEffect(()=>{
    if(progress && vin){
      getVin(false, urlParams, vin)
    }
  }, [progress])

  useEffect(()=>{
    if(infoType === 'upkeep' && tempRef.current.upkeep){
      getUpkeep()
    }
  }, [infoType])
  

  const getVin = (loading: boolean, urlParamsOld: string, pVin: string, brandcode?: string, mcid?: string) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    if(urlParamsOld !== urlParamsTemp){
      return
    }
    let param: any = {
      vin: pVin
    }
    if(brandcode){
      param.brandCode = brandcode
    }
    if(mcid){
      param.mcid = mcid
    }
    setSelectConfig({data: {}})
    setSelectConfigVisible(false)
    if(loading){
      setLoading(true)
    }
    // axios.all([vinApi.vin(param), vinApi.upkeep({vin: pVin})]).then(axios.spread((result, upkeepResult) => {
    vinApi.vin(param).then((result: any) => {
      const urlParamsTemp = JSON.stringify(getUrlParams()) || null
      if(urlParamsOld !== urlParamsTemp){
        return
      }
      if(result.code === 1 || result.code === 10) {
        let vinTemp = result.data.vin || vin
        let tempCrumb: any = [
          {
            type: 'logo',
            img: `${cdnsPath}/img/${result.data.brandCode || result.brandCode}.png`
          },
          {
            name: vinTemp.length > 20 ? vin : vinTemp
          }
        ]
        
        result.title?.map((name: string)=>{
          tempCrumb.push({
            name,
            type: 'config'
          })
        })
        if (result.data && result.data.maindata) {
          result.data.config_list = []
          if (!result.data.mainkeys) {
            result.data.config_list = Object.keys(data.data.maindata);
          }
          result.data.mainkeys.forEach((item:any) => {
            result.data.config_list.push({
              title: item,
              value: result.data.maindata[item],
            })
          })
        }
        result.data.upkeep = {}

        window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
        if(!samecrumb){
          setStore({value: tempCrumb, type: 'crumb'})
        }
        let vinDataTemp = {
          ...param,
          data: result
        }
        
        if(vinTemp.length > 20 || vin.length < 10){
          navigate(`/dataedit/vin?vin=${vinTemp}`)
        }
        
        if(name){
          setPartKey(name)
          setPartSearchkey(1)
          navigate(`/dataedit/vin?vin=${vinTemp}`)
        }
        if(!vinmcid){
          const url = window.location.search + `&vinmcid=${result.data.mcid}&vincode=${result.data.brandCode || result.brandCode}`
          navigate(url)
        }

        setData(result)
        getGroup({
          vin: vinTemp,
          brandCode: result.data.brandCode || result.brandCode,
          mcid: result.data.mcid
        }, vinDataTemp)
      }else if(result.code !== 4001) {
        setLoading(false)
      }
      if(result.code === 4005) {
        let list: any = []
        let columns: any = []
        if(result.select_data){
          result.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.select_data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.title = result.title
            obj.key = index
            if(item.vehicleid){
              obj.vehicleid = item.vehicleid
            }
            list.push(obj)
          })
        }else {
          result.data.mcid.title.forEach((item: any)=>{
            columns.push({
              title: item,
              dataIndex: item
            })
          })
          result.data.mcid.data.forEach((item: any, index: number)=>{
            let obj: any = {}
            item.contentData.forEach((content: any, i: number)=>{
              obj[result.data.mcid.title[i]] = content
            })
            obj.mcid = item.mcid
            obj.vin = result.data.vin
            obj.brandCode = result.data.brandCode
            obj.title = result.title
            obj.key = index
            list.push(obj)
          })
        }

        result.columns = columns
        result.list = list

        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4003){
        let columns = [
          {
            title: '图片',
            dataIndex: 'full_imglogo',
            type: 'img'
          },
          {
            title: 'vin',
            dataIndex: 'vehicleid'
          },
          {
            title: '名称',
            dataIndex: 'name'
          },
          {
            title: '年份',
            dataIndex: 'year'
          }
        ]
        
        result.data.forEach((item: any)=>{
          item.vehicleid = item.vin
          delete item.vin
        })
        result.columns = columns
        result.list = result.data
        let sc = {
          ...param,
          data: result
        }
        setSelectConfig(sc)
        setSelectConfigVisible(true)
      }
      if(result.code === 4001) {
        if(progress){
          if(progress < 3){
            setTimeout(()=>{
              setProgress(progress + 1)
            }, 3000)
          }else {
            // setProgressVisible(false)
            setLoading(false)
            setProgress(0)
            Toast(result.msg, 'warning')
          }
        }else {
          // setProgressVisible(true)
          setTimeout(()=>{
            setProgress(1)
          }, 3000)
        }
      }
    })
    // vinApi.vin(param).then((result: any)=>{
      
    // })
  }
  const getGroup = (param: object, vd?: {}) => {
    if(name){
      setPartKey(name)
      setPartSearchkey(1)
    }
    vinApi.group(param).then((result: {code: number, data: any})=>{
      setLoading(false)
      if(result.code === 1) {
        result.data.forEach((item: any)=>{
          if(item.group_type === 'maintenance'){
            item.tempNum = '8888'
          }
        })
        if(result.data[num || 0]?.click === 0){
          result.data.forEach((item: any)=>{
            if(item.click === 1){
              const url = window.location.search.replace(/&num=\d/, '&num=' + item.num)
              navigate(url)
            }
          })
        }
        let vinDataTemp = vd || JSON.parse(JSON.stringify(vinData))
        if(!vd && !vin && mcid){
          vinDataTemp.mcid = mcid.replace(/\=/g, '%3D')
          vinDataTemp.brandcode = brandcode
        }
        vinDataTemp.group = result.data
        sessionStorage.setItem('vinData', JSON.stringify(vinDataTemp))
        setStore({value: vinDataTemp, type: 'vinData'})
        setGroup(result.data)
      }
    })
  }
  const getStructure = (index: number, jump?: boolean) => {
    setStore({ type: 'editKey', value: '' })
    // if(groupActive === index){
    //   return
    // }
    if(group[index].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }

    if(jump && (vinData.vin || vinData.mcid) && vinData.structure){
      return
    }

    activeIndex = index
    setGroupActive(index)
    setSubGroupShow({
      menu: 'main',
      content: false
    })
    setStructureActive(-1)

    const item = group[index]
    const param: any = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num
    }
    let api: any = {}
    if(item.group_type){
      param.group_type = item.group_type
      api = vinApi.subGroup(param)
    }else {
      param.num = item.num
      api = vinApi.structure(param)
    }
    setStructure([])
    setSubGroup([])
    api.then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === index) {
        let total = 0
        result.data.forEach((d: any)=>{
          if(item.group_type){
            d.img = d.img_url
            d.mcid = item.mcid
            d.brandCode = item.brandCode
            d.num = item.tempNum
          }else {
            // d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
            if(result.imgs) d.img = result.imgs[d.structure_illustration] || result.imgs[d.imageLarge] || ''
          }
          
          
          // catalog
          if((mcid && brandcode) || item.group_type){
            d.colorvalue = 1
          }

          if(d.colorvalue){
            total++
          }
        })
        groupTotal.structure = total

        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        vinDataTemp.structure = result.data
        vinDataTemp.groupTotal = groupTotal
        setStore({value: vinDataTemp, type: 'vinData'})

        setGroupTotal(groupTotal)
        setStructure(result.data)
      }
    })
    if(!jump){
      let url = `/dataedit/vin?vin=${vin}&brandcode=${brandcode || vincode}&num=${item.num || item.tempNum}`
      //catalog
      if(mcid && brandcode){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode || vincode}&num=${item.num}`
      }
      
      item.name = item.label
      item.type = 'group'
      let tempCrumb = JSON.parse(window.sessionStorage.getItem('crumb') || '[]')
      
      const tempCrumbPop = tempCrumb[tempCrumb.length - 1]
      if(tempCrumbPop.type !== 'config' && tempCrumbPop.level === item.level){
        tempCrumb.pop()
      }
      tempCrumb.push(item)
      setStore({value: tempCrumb, type: 'crumb'})
      
      navigate(url)
    }else if(samecrumb){
      const url = window.location.search.replace('&samecrumb=true', '')
      navigate(url)
    }
  }

  const getSubGroup = (i: number, jump?: boolean, type?: any) => {
    setStore({ type: 'editKey', value: '' })
    let menuIndex: any = null
    let tempSub = JSON.parse(JSON.stringify(subGroup))
    if((type || type === 0) && type !== 'structure'){
      menuIndex = type + 1
    }
    // if(structureActive === i && type === 'structure'){
    //   return
    // }
    if(type === 'structure' && structure[i].click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    if(jump && (vinData.vin || vinData.mcid) && vinData.structure){
      return
    }
    activeIndex = i
    setStructureActive(i)
    setSubGroupShow({
      menu: type || type === 0 ? type : subGroup.length - 1,
      content: true
    })
    let item: any = {}
    if(type === 'structure'){
      item = structure[i]
    }else if(menuIndex) {
      item = subGroup[type][i]
    }else {
      item = subGroup[subGroup.length - 1][i]
    }
    const param = {
      vin,
      mcid: item.mcid,
      brandCode: item.brandCode,
      num: item.num,
      subgroup: item.mid,
      maingroup: item.maingroup,
      level: item.level
    }
    
    if(item.level === 1){
      setSubGroup([[]])
    }else if(menuIndex){
      tempSub.length = menuIndex
      groupTotal.sub.length = menuIndex
      tempSub.push([])
      groupTotal.sub.push(0)
      setGroupTotal(groupTotal)
      setSubGroup(tempSub)
    }
    
    let tempIndex: any = [i]
    if(subindex){
      tempIndex = subindex.split(',')
      if(menuIndex){
        tempIndex.length = menuIndex
      }
      if(type === 'structure') {
        tempIndex = []
      }
      tempIndex.push(i)

    }
    tempIndex = tempIndex.join(',')
    let url = `/dataedit/vin?vin=${vin}&brandcode=${brandcode}&num=${item.num}&subindex=${tempIndex}`
    //catalog
    if(mcid && brandcode){
      url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode}&num=${item.num}&subindex=${tempIndex}`
    }
    if(crumb[crumb.length - 1].level === item.level){
      crumb.pop()
    }
    
    item.type = 'subGroup'
    crumb.push(item)


    vinApi.structure(param).then((result: {code: number, data: object[], imgs: object[]})=>{
      if(result.code === 1 && activeIndex === i) {
        let vinDataTemp = JSON.parse(JSON.stringify(vinData))
        let total = 0
        result.data.forEach((d: any)=>{
          d.img = d.structure_illustration ? result.imgs[d.structure_illustration] || '' : result.imgs[d.imageLarge] || ''
          // catalog
          if(mcid && brandcode){
            d.colorvalue = 1
          }
          if(d.colorvalue){
            total++
          }
        })
        
        if(item.level === 1){
          groupTotal.sub = [total]
          setSubGroup([result.data])
          vinDataTemp.subGroup = [result.data]
        }else {
          if(menuIndex){
            tempSub.length = menuIndex
            groupTotal.sub.length = menuIndex
          }
          tempSub.push(result.data)
          groupTotal.sub.push(total)
          
          setSubGroup(tempSub)
          vinDataTemp.subGroup = tempSub
        }
        setGroupTotal(groupTotal)
        vinDataTemp.groupTotal = groupTotal
        setStore({value: vinDataTemp, type: 'vinData'})
        sessionStorage.setItem('vinData', JSON.stringify(vinDataTemp))
        if(!type && type !== 0){
          setStore({value: crumb, type: 'crumb'})
          navigate(url)
        }
      }
    })
    
    
    if(type || type === 0){
      setStore({value: crumb, type: 'crumb'})
      navigate(url)
    }
    
  }
  const goDetail = (i: number) => {
    let item: any = structure[i]
    let param = ''
    if(subindex){
      item = subGroup[subGroup.length - 1][i]
      item.index = i
      sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
    }else {
      sessionStorage.removeItem('subGroup')
    }
    item.type = 'detail'
    crumb.push(item)
    setStore({value: crumb, type: 'crumb'})

    param = `vin=${vin || ''}&brandcode=${item.brandCode}&mcid=${item.mcid}&num=${item.num || item.tempNum}&index=${i}&mid=${item.mid}`
    if(subindex){
      param += `&subindex=${subindex}`
    }
    sessionStorage.setItem('structure', JSON.stringify(structure))
    
    // if(subindex || subindex === 0){
    //   const subItem = subGroup[subGroup.length - 1][subindex]
    //   param = `vin=${vin || ''}&brandcode=${subItem.brandCode}&mcid=${subItem.mcid}&num=${subItem.num}&index=${i}&subindex=${subindex}`
    //   sessionStorage.setItem('subGroup', JSON.stringify(subGroup))
    //   subItem.type = 'detail'
    //   subItem.index = i
    //   crumb.push(subItem)
    //   setStore({value: crumb, type: 'crumb'})
    // }else {
      
    //   setStore({value: crumb, type: 'crumb'})
    //   sessionStorage.removeItem('subGroup')
    // }
    if(item.final_id){
      param += `&finalid=${item.final_id}`
    }
    let url = `/dataedit/vin/detail?${param}`
    //catalog
    if(mcid && brandcode){
      url = `/group/detail?${param}`
    }

    let tempCrumb = window.sessionStorage.getItem('crumb') || '[]'
    // window.sessionStorage.setItem('groupCrumb', tempCrumb)
    window.sessionStorage.setItem('crumb', JSON.stringify(crumb))
    navigate(url)
  }
  const setFilterLS = () => {
    let val = filter ? false : true
    setFilter(val)
    if(val){
      localStorage.setItem('filter', '1')
    }else {
      localStorage.removeItem('filter')
    }
  }
  const setStructureShowLS = () => {
    let val = structureShow ? false : true
    setStructureShow(val)
    if(val){
      localStorage.setItem('structureShow', '1')
    }else {
      localStorage.removeItem('structureShow')
    }
  }
  const setSubGroupShowFn = (type: any) => {
    let temp = JSON.parse(JSON.stringify(subGroupShow))
    temp.menu = type
    setSubGroupShow(temp)
  }

  const searchConfig = (key_words?: string) => {
    let api = vinApi.config
    if(key_words){
      api = vinApi.configSearch
    }
    api({vin, brandCode: brandcode || vincode, key_words}).then((result: {code: number, data: any})=>{
      if(result.code === 1){
        setConfig(result.data)
      }else {
        setConfig([])
      }
    })
  }

  const back = () => {
    crumb.splice(crumb.length - 1, 1)
    setStore({value: crumb, type: 'crumb'})
    sessionStorage.setItem('crumb', JSON.stringify(crumb))
    if(!subindex){
      if(mcid && brandcode){
        navigate('/search')
      }else {
        setGroupActive(-1)
      }
    }else {
      let subindexArr = subindex.split(',')
      let subGroupTemp = JSON.parse(JSON.stringify(subGroup))
      let groupTotalTemp = JSON.parse(JSON.stringify(groupTotal))
      let url = `/dataedit/vin?vin=${vin}&num=${num}&brandcode=${brandcode || vincode}`
      subGroupTemp.pop()
      groupTotalTemp.sub.pop()
      setSubGroup(subGroupTemp)
      setGroupTotal(groupTotalTemp)

      //catalog
      if(mcid && brandcode){
        url = `/group?mcid=${mcid.replace(/\=/g, '%3D')}&brandcode=${brandcode || vincode}&num=${num}`
      }
      if(subindexArr.length > 1){
        const activeMenu = subindexArr.pop()
        setSubGroupShow({
          menu: subindexArr.length === 1 ? 'structure' : activeMenu,
          content: true
        })
        url += `&subindex=${subindexArr.join(',')}`
      }else {
        if(num){
          group.forEach((gitem: any, index: number)=>{
            if(gitem.num == num){
              setGroupActive(index)
            }
          })
        }
        setSubGroupShow({
          menu: 'main',
          content: false
        })
      }
      
      navigate(url)
    }
    
  }
  const clickConfig = (item: any) => {
    if(!item.vin){
      getVin(true, urlParams, item.vehicleid || vin, selectConfig.data.brandCode, item.mcid)
    }else {
      const result = selectConfig.data
      setSelectConfigVisible(false)

      let tempCrumb: any = [
        {
          type: 'logo',
          img: `${cdnsPath}/img/${result.brandCode}.png`
        },
        {
          name: vin
        }
      ]
      
      result.title?.map((name: string)=>{
        tempCrumb.push({
          name,
          type: 'config'
        })
      })
      if (result.data && result.data.maindata) {
        result.data.config_list = []
        if (!result.data.mainkeys) {
          result.data.config_list = Object.keys(data.data.maindata);
        }
        result.data.mainkeys.forEach((item:any) => {
          result.data.config_list.push({
            title: item,
            value: result.data.maindata[item],
          })
        })
      }
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      if(!samecrumb){
        setStore({value: tempCrumb, type: 'crumb'})
      }
      let vinDataTemp = JSON.parse(JSON.stringify(selectConfig))

      if(name){
        setPartKey(name)
        setPartSearchkey(1)
        navigate(`/dataedit/vin?vin=${vin}`)
      }

      setData(result)
      getGroup({
        vin,
        brandCode: result.brandCode,
        mcid: item.mcid
      }, vinDataTemp)
      if(!vinmcid){
        const url = window.location.search + `&vinmcid=${item.mcid}&vincode=${result.brandCode}`
        navigate(url)
      }
    }
  }
  const getUpkeep = () => {
    tempRef.current.upkeep = false
    setUpkeepLoading(true)
    vinApi.upkeep({vin}).then((upkeepResult: any) => {
      setUpkeepLoading(false)
      if(upkeepResult.code === 1){
        let temp = JSON.parse(JSON.stringify(data))
        if(upkeepResult.data.MaintInfo){
          const maintInfo = upkeepResult.data.MaintInfo
          let oil = []
          let air = []
          let pollen = []
          let fuel = []
          let spark = []
          if(maintInfo.OilFilterReplaceIntervalsKm){
            oil.push(`${maintInfo.OilFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.OilFilterReplaceIntervalsMonth){
            oil.push(`${maintInfo.OilFilterReplaceIntervalsMonth}个月`)
          }
          if(oil.length){
            oil.push('次')
            upkeepResult.data.MaintInfo.oil = oil.join('/')
          }

          if(maintInfo.AirCleanerElementReplaceIntervalsKm){
            air.push(`${maintInfo.AirCleanerElementReplaceIntervalsKm}KM`)
          }
          if(maintInfo.AirCleanerElementReplaceIntervalsMonth){
            air.push(`${maintInfo.AirCleanerElementReplaceIntervalsMonth}个月`)
          }
          if(air.length){
            air.push('次')
            upkeepResult.data.MaintInfo.air = air.join('/')
          }

          if(maintInfo.PollenFilterReplaceIntervalsKm){
            pollen.push(`${maintInfo.PollenFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.PollenFilterReplaceIntervalsMonth){
            pollen.push(`${maintInfo.PollenFilterReplaceIntervalsMonth}个月`)
          }
          if(pollen.length){
            pollen.push('次')
            upkeepResult.data.MaintInfo.pollen = pollen.join('/')
          }

          if(maintInfo.FuelFilterReplaceIntervalsKm){
            fuel.push(`${maintInfo.FuelFilterReplaceIntervalsKm}KM`)
          }
          if(maintInfo.FuelFilterReplaceIntervalsMonth){
            fuel.push(`${maintInfo.FuelFilterReplaceIntervalsMonth}个月`)
          }
          if(fuel.length){
            fuel.push('次')
            upkeepResult.data.MaintInfo.fuel = fuel.join('/')
          }

          if(maintInfo.SparkPlugsReplaceIntervalsKm){
            spark.push(`${maintInfo.SparkPlugsReplaceIntervalsKm}KM`)
          }
          if(maintInfo.SparkPlugsReplaceIntervalsMonth){
            spark.push(`${maintInfo.SparkPlugsReplaceIntervalsMonth}个月`)
          }
          if(spark.length){
            spark.push('次')
            upkeepResult.data.MaintInfo.spark = spark.join('/')
          }
        }
        temp.data.upkeep = upkeepResult.data
        setData(temp)
      }
    })
  }

  const setEditChange = (key: string) => {
    let editKeyArr: any = editKey.split(",")
    let i = editKeyArr.indexOf(key)
    if(i !== -1) {
      editKeyArr.splice(i, 1)
    }else {
      editKeyArr.push(key)
    }
    setStore({type: 'editKey', value: editKeyArr.join(",") })
  }


  const clearVin = () => {
    setStore({ type: 'routekey', value: store.routekey + 1 })
    setStore({ type: 'vinData', value: {} })
    sessionStorage.removeItem("vinData")
    setStore({ type: "crumb", value: [] })
    sessionStorage.removeItem("crumb")
    setStore({ type: 'editKey', value: '' })
  }

  // miangroup
  const submitGroupDetail = (type: string) => {
    let params = {
      vin,
      brandCode: vinData.data.brandCode,
      group_list: groupDetail,
      status: type === 'submit' ? 1 : 0
    }
    setSubmitLoading({...submitLoading, [type]: true})
    vinApi.updateGroup(params).then((res: any) => {
      setSubmitLoading({...submitLoading, [type]: false})
      if(res.code === 1) {
        message.success("操作成功！")
        setEditChange("miangroup")
        clearVin()
        navigate(`/dataedit/vin?vin=${vin}`,{replace: true})
      }
    })
  }

  // subgroup
  const startSubEdit = () => {
    setEditChange("subgroup")
    if(subGroupShow.content) {
      setSubGroupDetail(JSON.parse(JSON.stringify(subGroup[subGroup.length - 1])))
    }else {
      setSubGroupDetail(JSON.parse(JSON.stringify(structure)))
    }
  }

  const toAddSubgroupItem = () => {
    const { num, subindex } = getUrlParams()
    let subgroup = ''
    let level = 1
    let full_path = ''
    let has_child = 0
    if(subindex) {
      let subindexArr = subindex.split(",")
      let subLen = subindexArr.length
      let lastActiveItem: any = {}
      let index = parseInt(subindexArr[subLen - 1])
      if(subindexArr.length === 1) {
        lastActiveItem = vinData.structure[index]
        has_child = vinData.subGroup[0][0]?.has_child || 0
      }else {
        lastActiveItem = vinData.subGroup[subLen - 2][index]
        has_child = vinData.subGroup[subLen - 1][0]?.has_child || 0
      }
      subgroup = lastActiveItem.subgroup
      level = level + subindexArr.length
      full_path = lastActiveItem.full_path
    }else {
      has_child = structure[0]?.has_child || 0
    }
    setActiveSubItem({
      ctype: 1,
      "num": num,
      "maingroup": num,
      "subgroup": subgroup,
      "mid": "",
      "name": "",
      "description": "",
      "model": "",
      "level": level,
      "has_child": has_child,
      "p_name": "",
      "full_path": full_path,
      "imageLarge": "",
      "mcid": vinData.data?.data?.mcid || "",
      "psort": 0,
      "brandCode": vinData.brandCode || vinData.data.brandCode,
      "structure_illustration": "",
      "colorvalue": 1
    })
    setSubEditOpen(true)
  }

  const submitSubgroupDetail = (type: string) => {

    

    let _subGroupDetail = subGroupDetail.map((item: any) => {
      if(item.ctype) {
        item.full_path = item.full_path + '/' + item.name
      }
      return item
    })
    let params = {
      vin,
      brandCode: vinData.data.brandCode,
      structure_list: _subGroupDetail,
      status: type === 'submit' ? 1 : 0,
      num
    }
    setSubmitLoading({...submitLoading, [type]: true})
    vinApi.updateStructure(params).then((res: any) => {
      setSubmitLoading({...submitLoading, [type]: false})
      if(res.code === 1) {
        message.success("操作成功！")
        setEditChange("subgroup")

        const { num, subindex } = getUrlParams()
        if(subindex) {
          let subindexArr = subindex.split(",")
          if(subindexArr.length === 1) {
            getSubGroup(subindexArr[0], false, 'structure')
          }else {
            getGroup(subindexArr[subindexArr.length - 2][subindexArr.length - 1])
          }
        }else if(num) {
          let index = vinData.group.findIndex((it: any) => it.num === num)
          getStructure(index)
        }
        return false
        
      }
    })
  }

  // e1
  
  
  const configDetailCon = useMemo(() => {
    const addItem = () => {
      let _configDetail: any = JSON.parse(JSON.stringify(configDetail))
      _configDetail.push({title: '', value: ''})
      setConfigDetail(_configDetail)
    }
    const delItem = (index: number) => {
      let _configDetail = JSON.parse(JSON.stringify(configDetail))
      _configDetail.splice(index, 1)
      setConfigDetail(_configDetail)
    }
    const updateItem = (index: number, key: string, value: any) => {
      let _configDetail = JSON.parse(JSON.stringify(configDetail))
      _configDetail[index][key] = value
      setConfigDetail(_configDetail)
    }
    const submit = (type: string) => {
      let _maindata: any = {}
      configDetail.forEach((it: any) => {
        _maindata[it.title] = it.value
      })
      let params = {
        vin,
        brandCode: vinData.data.brandCode,
        maindata: _maindata,
        status: type === 'submit' ? 1 : 0
      }
      setSubmitLoading({...submitLoading, [type]: true})
      vinApi.updateVinConfig(params).then((res: any) => {
        setSubmitLoading({submit: false, save: false})
        if(res.code === 1) {
          message.success("操作成功")
          setStore({type: 'editKey', value: "" })
          clearVin()
          navigate(`/dataedit/vin?vin=${vin}`,{replace: true})
        }
      })
    }

    const editConfigDetail = () => {
      setEditChange("e1")
      let _data = data.data && data.data.config_list && data.data.config_list ? JSON.parse(JSON.stringify(data.data.config_list)) : []
      setConfigDetail(_data)
    }
    return (<div className={css['detail']}>
      {
        editKey.indexOf('e1') !== -1 ? (
          <>
            <div>
              {
                configDetail.map((item: any, index: number) => {
                  return (
                    <Row className='mt10 plr20' gutter={10} align="middle">
                      <Col span={2} >
                        <i 
                          className='iconfont icon-jianshao d-color pointer' 
                          onClick={() => {
                            delItem(index)
                          }} 
                        /> 
                      </Col>
                      <Col span={10}>
                        <Input 
                          value={item.title} 
                          onChange={(e: any) => updateItem(index, 'title', e.target.value)} 
                        />
                      </Col>
                      <Col span={11}>
                        <Input value={item.value} onChange={(e: any) => updateItem(index, 'value', e.target.value)}   />
                      </Col>
                    </Row>
                  )
                })
              }
              <Row className='mt10 plr20'  align="middle">
                <Col span={2} >
                  <i className='iconfont icon-tianjia p-color pointer' onClick={() => addItem()} /> 
                </Col>
              </Row>
            </div>
            <div className={css['update-tools']}>
              <div className='flex-s-c'>
                <Button className='mr10' onClick={() => setEditChange("e1")}>取消</Button>
                <Button loading={submitLoading['save'] ? true : false} className='mr10' type="primary" ghost onClick={() => submit("save")}>保存</Button>
                <Button loading={submitLoading['submit'] ? true : false} type="primary" onClick={() => submit("submit")}>发布</Button>
              </div>
            </div>
          </>
        ): (
          <>
            {
              data.data && data.data.config_list && data.data.config_list.map((item: {title: string, value: string}, index: number)=>{
                return (
                  <p key={index}>
                    <label>{item.title}：</label>
                    <span>{item.value}</span>
                  </p>
                )
              })
            }
            <div className={css['update-tools']}>
              <Button onClick={() => editConfigDetail()}  style={{ background: '#FF7A00', borderColor: '#FF7A00' }} type="primary" icon={<i className='iconfont icon-bianji' />}>编辑</Button>
            </div>
          </>
        )
      }
    </div>
  )},[editKey, configDetail, data, submitLoading.submit, submitLoading.save])
  console.log(vinData)
  return (
    <>
      {
        vin || (mcid && brandcode) ? (
          <Loading visible={loading}>
            <div className={css['vin']}>
              <div className={css['l']}>
                <div className={css['box']}>
                  <div className={css['box-top']} onClick={()=>setSubGroupShowFn('main')}>
                    <div className={css['box-h']}>
                      <h2 onClick={() => setTab('group')} style={{ color: tab === 'group' ? '#1890FF' : '' }}>主组({group.length})</h2>
                      {/* <h2 onClick={() => setTab('special')} style={{ color: tab === 'special' ? '#1890FF' : '' }}>专项件</h2> */}
                    </div>
                    {/* <div className={css['box-top-tools']}>
                      <span>
                        <Input value={key} size="small" placeholder="搜索" onChange={(e)=>setKey(e.target.value)} />
                      </span>
                    </div> */}

                    {/* 主组编辑按钮 */}
                    {
                      groupActive === -1 && !subindex ? (
                        <div className={css['operate-tools'] + ' ml10 bgc-fff'}>
                          {
                            editKey && editKey.includes('maingroup') ? (
                              <>
                                <Button size="small" className='mr10' onClick={() => setEditChange("maingroup")}>取消</Button>
                                <Button onClick={() => submitGroupDetail("save")} size="small" loading={submitLoading['save'] ? true : false} className='mr10' type="primary" ghost >保存</Button>
                                <Button onClick={() => submitGroupDetail("submit")} className={css['submit']} size="small" loading={submitLoading['submit'] ? true : false} type="primary" >发布</Button>
                              </>
                            ): (
                              <Button onClick={() => {setEditChange("maingroup"); setGroupDetail(JSON.parse(JSON.stringify(group)))}} size="small" icon={<i className='iconfont icon-bianji' />} type="primary" style={{ background: '#FF7A00', borderColor: '#FF7A00' }}>编辑</Button>
                            )
                          }
                        </div>
                      ): null
                    }

                  </div>
                  {
                    tab === 'group' ? (
                      <>
                        {
                          subGroupShow.menu === 'main' ? (
                            <div className={css['box-content']} style={{height: `calc(100vh - ${190 + 46 * (structure.length ? subGroup.length : (subGroup.length ? subGroup.length + 1 : 0))}px)`}}>
                              <ul className={css['group']}>
                                {
                                  (editKey && editKey.includes("maingroup") ? groupDetail : group).map((item: {label: string, img: string, num: string, click?: number}, index: number) => {
                                    return (
                                      !key || item.label.indexOf(key) !== -1 || item.num.indexOf(key) !== -1 ? (
                                        <li  key={index} className={groupActive === index ? css['active'] +' '+ css['small'] : css['small']} 
                                          onClick={()=>{
                                            if(editKey && editKey.includes("maingroup")) {
                                              setActiveMainItem(item)
                                              setMainEditOpen(true)
                                            }else {
                                              getStructure(index)
                                            }
                                          }}
                                        >
                                          <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                          <p>{item.num} {item.label}</p>
                                          {
                                            item.click === 0 ? <div></div> : null
                                          }
                                        </li>
                                      ): null
                                    )
                                  })
                                }
                                {
                                  editKey && editKey.includes("maingroup") ? (
                                    <li 
                                      onClick={() => {
                                        setActiveMainItem({
                                          ctype: 1,
                                          colorvalue: 1,
                                          brandCode: vinData.brandCode || vinData.data.brandCode,
                                          num: '',
                                          mcid: vinData.data?.data?.mcid || "",
                                          label: '',
                                          img: '',
                                        })
                                        setMainEditOpen(true)
                                      }} 
                                      className={css['small'] + ' pointer'} style={{ fontSize: 45, color: '#888888' }}>
                                      +
                                    </li>
                                  ): null
                                }
                              </ul>
                            </div>
                          ): null
                        }
                        {
                          subGroupShow.content ? (
                            <>
                              <div className={css['box-top']} onClick={()=>setSubGroupShowFn('structure')}>
                                <h2>组({structure.length})</h2>
                                <div className={css['box-top-tools']}>
                                  <span>
                                    {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                                    {/* <i className="iconfont iconsousuo" /> */}
                                  </span>
                                </div>
                              </div>
                              {
                                subGroupShow.menu === 'structure' ? (
                                  <div className={css['box-content']} style={{height: `calc(100vh - ${190 + 46 * (structure.length ? subGroup.length : (subGroup.length ? subGroup.length + 1 : 0))}px)`}}>
                                    <ul className={css['group']}>
                                      {
                                        structure.map((item: {name: string, img: string, mid: string, click?: number}, i: number) => {
                                          return (
                                            !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                              <li key={i} className={subindex && (subindex.split(',')[0] == i) ? css['active'] : ''} onClick={()=>getSubGroup(i, false, 'structure')}>
                                                <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                                <p>{item.mid} {item.name}</p>
                                                {
                                                  item.click === 0 ? <div></div> : null
                                                }
                                              </li>
                                            ): null
                                          )
                                        })
                                      }
                                    </ul>
                                  </div>
                                ): null
                              }
                            </>
                          ): null
                        }

                        {
                          subGroup.map((groupItem: any, groupIndex: number)=>{
                            return (
                              subGroup.length - 1 > groupIndex ? (
                                <>
                                  <div key={groupIndex} className={css['box-top']} onClick={()=>setSubGroupShowFn(groupIndex)}>
                                    <h2>组({groupItem.length})</h2>
                                    <div className={css['box-top-tools']}>
                                      <span>
                                        {/* <input value={groupKey} type="text" placeholder="Group Search" onChange={(e)=>setGroupKey(e.target.value)} /> */}
                                        {/* <i className="iconfont iconsousuo" /> */}
                                      </span>
                                    </div>
                                  </div>
                                  {
                                    subGroupShow.menu == groupIndex ? (
                                      <div className={css['box-content']} style={{height: `calc(100vh - ${190 + 46 * (structure.length ? subGroup.length : subGroup.length + 1)}px)`}}>
                                        <ul className={css['group']}>
                                          {
                                            groupItem.map((item: {name: string, img: string, mid: string, click?: number}, i: number) => {
                                              return (
                                                !groupKey || item.name.indexOf(groupKey) !== -1 || item.mid.indexOf(groupKey) !== -1 ? (
                                                  <li key={i} className={subindex && (subindex.split(',')[groupIndex + 1] == i) ? css['active'] : ''} onClick={()=>getSubGroup(i, false, groupIndex)}>
                                                    <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                                    <p>{item.mid} {item.name}</p>
                                                    {
                                                      item.click === 0 ? <div></div> : null
                                                    }
                                                  </li>
                                                ): null
                                              )
                                            })
                                          }
                                        </ul>
                                      </div>
                                    ): null
                                  }
                                </>
                              ): null
                            )
                          })
                        }
                      </>
                    ): (
                      // 专项件
                      <div className={css['box-content']} style={{height: `calc(100vh - ${190 + 46 * (structure.length ? subGroup.length : (subGroup.length ? subGroup.length + 1 : 0))}px)`}}>
                        <ul className={css['group']}>
                          {
                            special_category.map((item: any, index: number) => {
                              return (
                                <li key={index} className={specialActive === index ? css['active'] +' '+ css['small'] : css['small']} onClick={()=>getStructure(index)}>
                                  <img src={item.standard_subgroup_name_img} />
                                  <p>{item.standard_subgroup_name}</p>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    )
                  }
                  
                </div>
              </div>

              {
                groupActive === -1 && !subindex ? (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        <div className={css['box-h']}>
                          <h2 className={infoType === 'config' ? css.active : ''} onClick={()=>setInfoType('config')}>配置详情</h2>
                          {
                            data.data && data.data.upkeep ? (
                              <h2 className={infoType === 'upkeep' ? css.active : ''} onClick={()=>setInfoType('upkeep')}>养护油品</h2>
                            ): null
                          }
                        </div>
                        <div className={css['box-top-tools']}>
                          {/* <div style={{marginLeft: 10}}>
                            <SearchPartInput 
                              mcid={mcid?.replace(/\=/g, '%3D') || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            />
                          </div> */}
                          
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        {
                          infoType === 'config' ? (
                            configDetailCon
                          ): null
                        }
                        {
                          infoType === 'upkeep' ? (
                            <Loading visible={upkeepLoading}>
                              <div className={css['detail']}>
                                {
                                  data.data.upkeep.CarInfo ? (
                                    <div className={css.upkeep}>
                                      <h5>原厂机油</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.CarInfo.JiChuYouLeiXing ? (
                                            <p><label>类型:</label><span>{data.data.upkeep.CarInfo.JiChuYouLeiXing}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo.Spec ? (
                                            <p><label>粘度:</label><span>{data.data.upkeep.CarInfo.Spec}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo.OilSpecs ? (
                                            <p><label>机油标注:</label><span>{data.data.upkeep.CarInfo.OilSpecs}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.CarInfo?.MaintenanceOils ? (
                                            <p><label>推荐加注量:</label><span>{data.data.upkeep.CarInfo.MaintenanceOils}L</span></p>
                                          ): null
                                        }
                                        {/* {
                                          data.data.upkeep.CarInfo?.ImagePath ? (
                                            <p><label>图片:</label><span><img src={data.data.upkeep.CarInfo?.ImagePath} height={40} onClick={()=>this.setState({imgUrl: data.data.upkeep.CarInfo?.ImagePath})} /></span></p>
                                          ): null
                                        } */}
                                      </ul>
                                    </div>
                                  ): null
                                }
                                {
                                  data.data.upkeep.GearBoxOil ? (
                                    <div className={css.upkeep}>
                                      <h5>变速箱</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.McidInfo?.transmission_description ? (
                                            <p><label>变速箱/档位:</label><span>{data.data.upkeep.McidInfo?.transmission_description}/{data.data.upkeep.CarInfo?.GearCount}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.GearBoxOil.BaoYangJiaZhuLiang ? (
                                            <p><label>换油量:</label><span>重力:{data.data.upkeep.GearBoxOil.BaoYangJiaZhuLiang}; 机器:{data.data.upkeep.GearBoxOil.XunHuanJiaZhuLiang}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.GearBoxOil.DaXiuJiaZhuLiang ? (
                                            <p><label>大修加注量:</label><span>{data.data.upkeep.GearBoxOil.DaXiuJiaZhuLiang}</span></p>
                                          ): null
                                        }
                                      </ul>
                                    </div>
                                  ): null
                                }
                                {
                                  data.data.upkeep.MaintInfo ? (
                                    <div className={css.upkeep}>
                                      <h5>养护信息</h5>
                                      <ul>
                                        {
                                          data.data.upkeep.MaintInfo.oil ? (
                                            <p><label>机油过滤器:</label><span>{data.data.upkeep.MaintInfo.oil}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.air ? (
                                            <p><label>空气过滤器:</label><span>{data.data.upkeep.MaintInfo.air}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.pollen ? (
                                            <p><label>空调过滤器:</label><span>{data.data.upkeep.MaintInfo.pollen}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.fuel ? (
                                            <p><label>燃油滤清器:</label><span>{data.data.upkeep.MaintInfo.fuel}</span></p>
                                          ): null
                                        }
                                        {
                                          data.data.upkeep.MaintInfo.spark ? (
                                            <p><label>火花塞:</label><span>{data.data.upkeep.MaintInfo.spark}</span></p>
                                          ): null
                                        }
                                      </ul>
                                    </div>
                                  ): null
                                }
                                
                              </div>
                            </Loading>
                          ): null
                        }
                      </div>
                    </div>
                    {
                      data.data && data.data.full_imglogo ? (
                        <img className={css['model-img']} alt="icon" src={data.data.full_imglogo} />
                      ): null
                    }
                  </div>
                ): (
                  <div className={css['r']}>
                    <div className={css['box']}>
                      <div className={css['box-top']}>
                        {
                          subGroupShow.content ? (
                            <h2>分组({ filter ? subGroup.length : groupTotal.sub[groupTotal.sub.length - 1] })</h2>
                          ): (
                            <h2>分组({ filter ? structure.length : groupTotal.structure })</h2>
                          )
                        }
                        
                        <div className={css['box-top-tools']}>
                          <button onClick={()=>back()} className={css['other']}><i className="iconfont icon-left" />返回</button>
                          <button className={css['other']} onClick={()=>setStructureShowLS()}><i className={structureShow ? "iconfont icon-qiehuantupian" : "iconfont icon-liebiao1"} style={{color: '#EBBC00'}} />{structureShow ? '图片' : '列表'}</button>
                          {
                            vin ? (
                              <>
                                {/* <button className={css['other']} onClick={()=>setConfigVisible(true)}><i className="iconfont icon-peizhi" style={{color: '#1890FF'}} />配置</button> */}
                                <button className={filter ? css['other'] + ' ' + css['active'] : css['other']} onClick={()=>setFilterLS()}><i className="iconfont icon-guolv" style={{color: '#616BF1'}} />{filter ? '未过滤' : '过滤'}</button>
                              </>
                            ): null
                          }
                          
                          {/* <div style={{marginLeft: 10}}>
                            <SearchPartInput 
                              mcid={mcid?.replace(/\=/g, '%3D') || (data.data && data.data.mcid) }
                              brandcode={brandcode || data.brandCode}
                              vin={vin || ''}  
                            />
                          </div> */}
                          {/* <span>
                            <input type="text" placeholder="输入零件原厂名／零件号" onChange={(e)=>setPartKey(e.target.value)} />
                            <i className="iconfont icon-sousuo" onClick={()=>setPartSearchkey(partSearchkey + 1)} />
                          </span> */}

                          <div className={css['operate-tools'] + ' ml10 bgc-fff'}>
                            {
                              editKey && editKey.includes("subgroup") ? (
                                <>
                                  <Button size="small" className='mr10' onClick={() => setEditChange("subgroup")}>取消</Button>
                                  <Button onClick={() => submitSubgroupDetail("save")} size="small" loading={submitLoading['save'] ? true : false} className='mr10' type="primary" ghost >保存</Button>
                                  <Button onClick={() => submitSubgroupDetail("submit")} className={css['submit']} size="small" loading={submitLoading['submit'] ? true : false} type="primary" >发布</Button>
                                </>
                              ): (
                                <Button 
                                  onClick={() => {
                                    startSubEdit()
                                  }} 
                                size="small" icon={<i className='iconfont icon-bianji' />} type="primary" style={{ background: '#FF7A00', borderColor: '#FF7A00' }}>编辑</Button>
                              )
                            }
                          </div>
                        </div>
                      </div>
                      <div className={css['box-content']}>
                        <div className={css['detail']}>
                          {
                            structureShow ? (
                              <>
                                <table className={css['table-header']}>
                                  <thead>
                                    <tr>
                                      <th style={{width: '10%'}}>图片</th>
                                      <th style={{width: '5%'}}>MG</th>
                                      <th style={{width: '5%'}}>SG</th>
                                      <th style={{width: '10%'}}>ILL-NO</th>
                                      <th style={{width: '15%'}}>名称</th>
                                      <th style={{width: '15%'}}>备注</th>
                                      <th style={{width: '40%'}}>车型</th>
                                    </tr>
                                  </thead>
                                </table>
                                <div className={css['list']}>
                                  <table>
                                    <tbody>
                                    {
                                      subGroupShow.content ? (
                                        subGroup[subGroup.length - 1].map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? (css.grey + ' ' + css['filter']) : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      ): (
                                        structure.map((item: any, index: number)=>{
                                          return (
                                            filter || item.colorvalue ? (
                                              <tr key={index} className={item.colorvalue ? (item.click === 0 ? css.grey : '') : (item.click === 0 ? css.grey : css['filter'])} onClick={()=>{if(item.has_child){getSubGroup(index, false, 'structure')}else {goDetail(index)}}}>
                                                <td style={{width: '10%'}}><img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} /></td>
                                                <td style={{width: '5%'}}>{item.maingroup}</td>
                                                <td style={{width: '5%'}}>{item.subgroup}</td>
                                                <td style={{width: '10%'}}>{item.mid}</td>
                                                <td style={{width: '15%'}}>{item.name || item.standard_subgroup_name}</td>
                                                <td style={{width: '15%'}}>{item.description}</td>
                                                <td style={{width: '40%'}}>{item.model}</td>
                                              </tr>
                                            ): null
                                          )
                                        })
                                      )
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </>
                            ): (
                              <ul className={css['group']}>
                                {
                                  subGroupShow.content ? (
                                    editKey && editKey.includes("subgroup") ? subGroupDetail.map((item: any, index: number)=>{
                                      // 编辑状态
                                      return (
                                        <div>
                                          <li key={index}  className={item.colorvalue ? '' : css['filter']}
                                            onClick={() => {
                                              setSubEditOpen(true)
                                              setActiveSubItem(item)
                                            }}
                                          >
                                            <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                            <p>{item.mid}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                          <div className='center mt5'>
                                            {
                                              item.colorvalue ? (
                                                <Button onClick={() => { item.colorvalue = 0; setChange(c => !c) }} size="small" type="primary" ghost danger>加入过滤</Button>
                                              ): (
                                                <Button onClick={() => { item.colorvalue = 1; setChange(c => !c) }} size="small" type="primary" ghost >移出过滤</Button>
                                              )
                                            }
                                          </div>
                                        </div>
                                      )
                                    }): (
                                      // 正常状态
                                      subGroup[subGroup.length - 1].map((item: any, index: number) => {
                                        return (
                                          filter || item.colorvalue ? (
                                            <li key={index} onClick={()=>{if(item.has_child){getSubGroup(index)}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                              <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                              <p>{item.mid}</p>
                                              {
                                                item.click === 0 ? <div></div> : null
                                              }
                                            </li>
                                          ): null
                                        )
                                      })
                                    )
                                  ): (
                                    editKey && editKey.includes("subgroup") ? subGroupDetail.map((item: any, index: number)=>{
                                      // 编辑状态
                                      return (
                                        <div>
                                          <li key={index}   className={item.colorvalue ? '' : css['filter']}
                                            onClick={() => {
                                              setSubEditOpen(true)
                                              setActiveSubItem(item)
                                            }}
                                          >
                                            <span className={css.name}>{item.name}</span>
                                            <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                            <p>{brandcode === 'benz' ? item.subgroup : (item.mid || item.standard_subgroup_name)}</p>
                                            {
                                              item.click === 0 ? <div></div> : null
                                            }
                                          </li>
                                          <div className='center mt5'>
                                            {
                                              item.colorvalue ? (
                                                <Button onClick={() => { item.colorvalue = 0; setChange(c => !c) }} size="small" type="primary" ghost danger>加入过滤</Button>
                                              ): (
                                                <Button onClick={() => { item.colorvalue = 1; setChange(c => !c) }} size="small" type="primary" ghost >移出过滤</Button>
                                              )
                                            }
                                          </div>
                                        </div>
                                      )
                                    }): (
                                      structure.map((item: any, index: number) => {
                                        return (
                                          filter || item.colorvalue ? (
                                            <li key={index}  onClick={()=>{if(item.has_child){getSubGroup(index, false, 'structure')}else {goDetail(index)}}} className={item.colorvalue ? '' : css['filter']}>
                                              <span className={css.name}>{item.name}</span>
                                              <img src={item.img || "https://cdns.007vin.com/img/empty_subgrounp.png"} />
                                              <p>{brandcode === 'benz' ? item.subgroup : (item.mid || item.standard_subgroup_name)}</p>
                                              {
                                                item.click === 0 ? <div></div> : null
                                              }
                                            </li>
                                          ): null
                                        )
                                      })
                                    )
                                  )
                                }

                                {/* 编辑状态下 - 新增 */}
                                {
                                  editKey && editKey.includes("subgroup") ? (
                                    <li style={{ fontSize: 45, color: '#888888' }}
                                      onClick={() => toAddSubgroupItem()}
                                    >
                                      +
                                    </li>
                                  ): null
                                }
                              </ul>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </Loading>
        ): null
      }
      
      {
        partSearchkey ? (
          <PartSearch
            key={partSearchkey}
            keyword={partKey}
            mcid={mcid?.replace(/\=/g, '%3D') || data.data.mcid }
            brandcode={brandcode || data.brandCode}
            vin={vin || ''}
          />
        ): null
      }
      <Modal
        title="配置"
        visible={configVisible}
        cancel={setConfigVisible}
      >
        <div className={css['config']}>
          <div className={css['search']}>
            <input placeholder="请输入配置信息" onChange={(e)=>searchConfig(e.target.value)} />
          </div>
          <div className={css['content']}>
            {
              Object.keys(config).map((key: string, index: number)=>{
                return (
                  <div className={css['item']} key={index}>
                    <label>{key}</label>
                    <span>{config[key]}</span>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      <Modal
        title={selectConfig.data.desc || '选择配置信息'}
        visible={selectConfigVisible}
        cancel={setSelectConfigVisible}
        width={1000}
      >
        <div className={css['select-config']}>
          <div className={css['select-brand']}>
            <span>{selectConfig.data.res_desc}</span>
            <img src={`${cdnsPath}/img/${selectConfig.data.brandCode}.png`} />
          </div>
          <table>
            <thead>
              <tr>
                {
                  selectConfig.data.columns && selectConfig.data.columns.map((item: any, index: number)=>{
                    return (
                      <th key={index}>{item.title}</th>
                    )
                  })
                }
              </tr>
            </thead>
          </table>
          <div className={css['select-scroll']}>
            <table>
              <tbody>
                {
                  selectConfig.data.list && selectConfig.data.list.map((item: any, index: number)=>{
                    return (
                      <tr onClick={()=>clickConfig(item)} key={index}>
                        {
                          selectConfig.data.columns.map((title: any, i: number)=>{
                            return (
                              <td key={i}>
                                {
                                  title.type === 'img' ? (
                                    <img src={item[title.dataIndex]} style={{height: 80}} />
                                  ): (
                                    <>{item[title.dataIndex]}</>
                                  )
                                }
                                
                              </td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
      {/* {
        progressVisible ? (
          <div className={css.pollingVin}>
            <div>
              <span style={{width: progress * 50 + '%'}}></span>
            </div>
          </div>
        ): null
      } */}


      {/* 主组编辑 */}

      <Modal
        title='主组编辑'
        visible={mainEditOpen}
        cancel={() => setMainEditOpen(false)}
      >
        <div className='p20'>
          <div className='flex-s-c'>
            <label style={{ width: 80 }} htmlFor="">主组号：</label>
            <Input style={{ flex: 1 }} value={activeMainItem.num} onChange={(e: any) => setActiveMainItem({...activeMainItem, num: e.target.value})} />
          </div>
          <div className='flex-s-c mt15'>
            <label style={{ width: 80 }} htmlFor="">名称：</label>
            <Input style={{ flex: 1 }} value={activeMainItem.label} onChange={(e: any) => setActiveMainItem({...activeMainItem, label: e.target.value})} />
          </div>
          <div className='flex-s-c mt15'>
            <label style={{ width: 80 }} htmlFor="">图片：</label>
            <UploadModel
              item={activeMainItem}
              uploadLabel='上传图片'
              lkey='img'
              width={380}
            />
          </div>
          <div className='right mt20'>
            <Button onClick={() => setMainEditOpen(false)}>关闭</Button>
            {
              activeMainItem.ctype ? (
                <Button className='ml10' type="primary" 
                  onClick={() => {
                    delete activeMainItem.ctype
                    setGroupDetail([...groupDetail, {...activeMainItem}])
                    setMainEditOpen(false)
                  }}
                >确定</Button>
              ): null
            }
          </div>
        </div>
      </Modal>

      
      {/* 分组编辑 */}
      <Modal
        title='分组/子组编辑'
        visible={subEditOpen}
        cancel={() => setSubEditOpen(false)}
      >
        <div className='p20'>
          <div className='flex-s-c'>
            <label style={{ width: 80 }} htmlFor="">图片：</label>
            <UploadModel
              item={activeSubItem}
              uploadLabel='上传图片'
              lkey='img'
              width={380}
            />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">主组：</label>
            <Input disabled style={{ flex: 1 }} value={activeSubItem.maingroup} onChange={(e: any) => { activeSubItem['maingroup'] = e.target.value; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">分组：</label>
            <Input disabled={activeSubItem.level > 1} style={{ flex: 1 }} value={activeSubItem.subgroup} onChange={(e: any) => { activeSubItem['subgroup'] = e.target.value; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">图号：</label>
            <Input style={{ flex: 1 }} value={activeSubItem.mid} onChange={(e: any) => { activeSubItem['mid'] = e.target.value; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">名称：</label>
            <Input style={{ flex: 1 }} value={activeSubItem.name} onChange={(e: any) => { activeSubItem['name'] = e.target.value; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">备注：</label>
            <Input style={{ flex: 1 }} value={activeSubItem.description} onChange={(e: any) => { activeSubItem['description'] = e.target.value; setChange(c => !c) }} />
          </div>
          <div className='flex-s-c mt10'>
            <label style={{ width: 80 }} htmlFor="">型号：</label>
            <Input style={{ flex: 1 }} value={activeSubItem.model} onChange={(e: any) => { activeSubItem['model'] = e.target.value; setChange(c => !c) }} />
          </div>
          
          <div className='right mt20'>
            <Button onClick={() => setSubEditOpen(false)}>关闭</Button>
            {
              activeSubItem.ctype ? (
                <Button className='ml10' type="primary" 
                  onClick={() => {
                    setSubGroupDetail([...subGroupDetail, {...activeSubItem}])
                    setSubEditOpen(false)
                  }}
                >确定</Button>
              ): null
            }
          </div>
        </div>
      </Modal>
      
    </>
  );
}

export default Vin
